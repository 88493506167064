import { fetchAPI } from '../../../api/fetch';
import { absurd } from '../../../utils/absurd';
import { isRawAdministrator } from '../administrator/guards';
import type { Administrator, RawAdministrator } from '../administrator/types';
import { isRawClient } from '../client/guards';
import { serializeRawClient } from '../client/serializers';
import type { RawUser, User } from '../types';

export const PATH = '/users/me';

export interface GetMeResponseBody {
  user: RawUser;
}

interface FetchMeProps {
  accessToken?: string;
}

export async function fetchMe({ accessToken }: FetchMeProps): Promise<{ user: User }> {
  const res = await fetchAPI(PATH, { accessToken });
  const json = (await res.json()) as GetMeResponseBody;
  return { user: serializeRawUser(json.user) };
}

export function serializeRawUser(rawUser: RawUser): User {
  if (isRawAdministrator(rawUser)) {
    return serializeRawAdministrator(rawUser);
  }

  if (isRawClient(rawUser)) {
    return serializeRawClient(rawUser);
  }

  throw absurd(rawUser);
}

function serializeRawAdministrator(rawAdministrator: RawAdministrator): Administrator {
  return {
    id: rawAdministrator.id,
    firstName: rawAdministrator.first_name,
    lastName: rawAdministrator.last_name,
    email: rawAdministrator.email,
    profiles: [
      {
        profileType: rawAdministrator.profiles[0].profile_type,
      },
    ],
  };
}
