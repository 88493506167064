import { useQuery } from '@tanstack/react-query';

import type { ApiQueryHook } from '../../api/types';
import { useAuth } from '../auth/useAuth';

import { fetchMe, PATH } from './api/fetchMe';

interface UseUserProps extends ApiQueryHook<typeof fetchMe> {
  logoutOn401?: boolean;
}

export function useUser({ logoutOn401, staleTime = Infinity, ...props }: UseUserProps = {}) {
  const { withAuth } = useAuth();
  const { data, ...rest } = useQuery({
    ...props,
    queryKey: [PATH],
    queryFn: withAuth(fetchMe, { logoutOn401 }),
    staleTime,
  });
  return { ...rest, user: data?.user };
}
